
@font-face {
    font-family: 'Goldplay';
    src: url('../fonts/Goldplay-Light.woff2') format('woff2'),
        url('../fonts/Goldplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldplay';
    src: url('../fonts/Goldplay-Black.woff2') format('woff2'),
        url('../fonts/Goldplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldplay';
    src: url('../fonts/Goldplay-Bold.woff2') format('woff2'),
        url('../fonts/Goldplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldplay';
    src: url('../fonts/Goldplay-Thin.woff2') format('woff2'),
        url('../fonts/Goldplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldplay';
    src: url('../fonts/Goldplay-Medium.woff2') format('woff2'),
        url('../fonts/Goldplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldplay';
    src: url('../fonts/Goldplay-SemiBold.woff2') format('woff2'),
        url('../fonts/Goldplay-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldplay';
    src: url('../fonts/Goldplay-Regular.woff2') format('woff2'),
        url('../fonts/Goldplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
p{
    color:#1f1f1f;
}

.cont_footer .col-3 h4{
    font-family: 'Goldplay'!important;
}
.navbar-nav a{
    padding: 0 15px!important;
}
.sticky .navbar-nav a{
    padding: 0 15px!important;
}

.fixbold{
    font-weight: 600!important;
    padding: 5px 0;
    color:#5c5c5c;
}
.listaicons{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}
.listaicons li{
    width: 50%;  
    padding: 3% 3% 3% 0;
    color: #fff;
}
.listaicons li .icon{
    width: 75px;
    margin-bottom: 25px;
}
.listaicons
.meinteresa .nav-link{
    color: #fff !important;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    padding: 8px 32px!important;
   
}
.listaicons
.meinteresa .nav-link{
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.listaicons
.meinteresa .nav-link {
    color: #ffffff !important;
    background: #039677 !important;
}
.listaicons
.meinteresa .nav-link:hover {
    color: #ffffff !important;
    background: rgb(0, 97, 76) !important;
    opacity: 1;
}
.listaicons
.meinteresa{
    width: auto;      
}
.item1.fixpad h5{
    color:#02d3a6;
    font-weight: 600 !important;
    font-size: 58px;
}
.item1.fixpad h2{
    font-size: 24px;
    color:#5c5c5c;
    margin-bottom: 20px;
}
.fdverde{
    background: #02d3a6;
    color:#fff;
}
.fdverde h5, .fdverde h2{
    color:#fff;
}
.fdverde2{
    background: url(../img/fdverde2.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.btna{
   width: auto;
    padding: 8px 12px;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    -webkit-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.btna.green {
    background:#00c69b!important;
}
.btna:hover{
    background:#01725a!important;
}

   

/*cursos*/

#cursos
.col-12.btns{
    padding: 0;
}
#cursos .col-6{
    padding: 0!important;
}
#cursos .col-6 h2{
    display: flex;
    align-items: center;
    color:#00c69b;
    font-size: 28px;
}
#cursos h2{
    color:#5c5c5c;
    font-size:24px
}

#cursos h2 img{
    margin-right: 5px;
}

#cursos .col-6 p{
    margin-bottom: 10px;
}

#cursos .col-6 p:last-child{
    margin-top: 15px;
    font-size: 24px;
    font-weight: 600;
}
#cursos .precio span{
    color:#00c69b;
    margin: 0 5px;
}
.tright{
    text-align: right;
}
#cursos .col-12.tright{
    align-items: flex-end !important;
    flex-direction: row-reverse;
    display: flex;
    padding:45px 25px;
}
#cursos .col-12.tright .col-6{
    align-items: flex-end!important;
}
#cursos .col-12.tright .col-6:last-child{
    align-items: flex-start !important;
}
#cursos
.frow .col-6:last-child {
    align-items: flex-end!important;
}
.mgtop15{
    margin-top:15px
}
.tleft{
    text-align: left!important;
}

.ocanaimg{
    width: 75%;
    padding: 0;
}
#cursos .frow{
    flex-direction: row;
    align-items: flex-start;
    display: flex;
    padding: 45px 25px;
}
#cursos .frow.inicio{
    padding: 45px 0;
}


#cursos .frow .img{
    display: flex;
    align-items: flex-end;
}
#cursos{
    align-items: flex-end !important;
    flex-direction: row-reverse;
}






/*****************/

h4, h2, h5, h6{
    font-family: 'Goldplay';
}
.fw600{
    font-weight: 600;
}
.btns button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px!important;
}
.btns img{
    width: 22px;
}
.verde{
    color: #00c69b!important;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}
.headerMain.sticky{
    display: flex;
}
.headerMain.sticky{
    background: #000000b0;
}
.headerMain.sticky
img.logo {
    max-height: 50px;
    margin-bottom: 0;
}
.headerMain.sticky .sub-menu{
    background: #000000b0;
}

body{
    font-family: 'Goldplay'!important;
}
p{
    font-family: 'Goldplay'!important;
}

/*navbar*/

.navbar-expand-lg .navbar-nav{
   display: flex;
   align-items: center;
}
.nav-link{
   height: 80px;
   display: flex;
   align-items: center;
   padding: 8px 12px!important;
}
.sticky
.nav-link{
    height: 60px;
    display: flex;
    align-items: center;
    padding: 8px 12px!important;
 }
.navbar-nav{
    font-weight: 600;
}
.navbar-nav a{
    display: flex;
    align-items: center;
}
.meinteresa a{
   -webkit-border-radius: 25px 25px 25px 25px;
   border-radius: 25px 25px 25px 25px;
}
.meinteresa img{
   width: 22px;
   margin-left: 5px;
}
.meinteresa .nav-link{
   height: auto;
   background: #00ebb8 !important;
   color: #00886a !important;
   margin:0 3px;
}
.meinteresa .nav-link:hover{
   color:#00886a!important;  
   opacity: .5;
}
.activasubmenu ul{
    display: block;
}
header .navbar{
    background: none!important;
}
.container-fluid{
    background: none!important;
}
.navbar-nav a{
    text-transform: initial!important;
    color: #fff!important;
}
.dropdown-item:hover{
    background: none;
}
.dropdown-menu{
    background: none;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    border: none;
}

@keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    animation-duration: 0.7s;
    animation-name: fadeInDown;
    animation-timing-function: ease-out;
  }


/*cookies*/


.intsection.cookiespage .col-12.fixpadding
{
    padding: 5px 0 5px 0!important;
}
.intsection.cookiespage{
    text-align: left;
    margin-top: 150px !important;
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 400;
    flex-direction: column;
    padding: 0 5%;
}
.intsection.cookiespage .col-12{
    padding: 15px 0;
    min-height: auto;
}
.intsection.cookiespage b{
    font-weight: 500;
    text-decoration: underline;
}
.intsection.cookiespage p{
    font-size: 20px!important;
    line-height: 24px!important;
    text-align: left;
    padding:0 0!important;
}
.intsection.cookiespage p:last-child{
    margin: 0 0 55px 0!important;
}
.intsection.cookiespage h2{
    font-size: 36px !important;
    line-height: 40px !important;
    margin-bottom: 25px;
    max-width: 1600px;
    padding: 0 0;
    text-align: left;
    width: 100%;
}
.intsection.cookiespage h2 p{
    margin-bottom: 0!important;
}
.intsection.cookiespage h2 p{
    margin-bottom: 0!important;
}
.intsection.cookiespage .col-12 h4{
    text-align: left;
    padding: 0;
}
.intsection.cookiespage h2 p{
    padding: 0!important;
}
.intsection.cookiespage ul{
    max-width: 1600px;
    margin-top:25px;
    padding: 0 3%;
}
.intsection.cookiespage ul p{
    padding:0 0!important;
}
.intsection.cookiespage ul p{
    margin-top:25px;
}
.intsection.cookiespage li{
    list-style: url("../img/arrowgreen.png");
}
.intsection.cookiespage a{
    text-decoration: none;
}
.cookiespage h2{
    font-size: 24px!important;
    font-weight: bold!important;
    margin:0;
}
.intsection.cookiespage .col-12 h4{
    font-size: 24px!important;
    font-weight: 600!important;
    margin:0 0 20px 0;
}
.intsection.cookiespage .col-12{
    max-width: 1600px;
}
.intsection.cookiespage  p {
    font-size: 18px;
    line-height: 24px;
    white-space: pre-line;
    vertical-align: bottom;
    margin: 5px 0;
}
.intsection.cookiespage
h2.mgbot0{
    margin-bottom:0!important;
}
.intsection.cookiespage
h2.mgbot0 p:last-child{
    margin-bottom:20px!important;
}
.intsection.cookiespage ul li a p:last-child{
    margin-bottom:5px!important;
}
.intsection.cookiespage
.mgbot0 p:last-child{
    margin-bottom:15px!important;
}
.intsection.cookiespage
.mgbot0:last-child p:last-child{
    margin-bottom:55px!important;
}
#privacidad {
    margin-bottom: 45px;
}
#privacidad h4{
    margin: 35px 0 15px 0;
    text-align: left;
    color: #5c5c5c !important;
    font-size: 24px !important;
    padding: 0 !important;
    width: 100%;
    max-width: 1600px;
}
#privacidad  h2{
    padding:0!important;
    margin:25px 0 5px 0!important;
}
#privacidad p{
    font-size: 20px!important;
    line-height: 28px!important;
}
#privacidad p:last-child{
    margin:0!important;   
}

.oculto{
    visibility: hidden;
}
.contpanelcookies{
    padding: 20px;
    
}
.conborde{
    border: 1.5px solid #333;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.cerrarcookies{
    display: flex;
    justify-content: flex-end;
    margin:0!important;
}
.cerrarcookies img{
    width: 40px;
}
.contpanelcookies p{
    margin: 0 15px!important;
    line-height: 30px;
    font-size: 18px;
}
.cerrarcookies button{
    border:none;
    margin: 0!important;
    top: -70px;
    right: 5px;
    width: 40px;
    height: 40px;
    position: relative;
    padding: 0;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}
.contpanelcookies h3{
    font-size: 24px;
    font-weight: bold;
}
.contpanelcookies .col-12{
    margin: 15px 0;
}
.cookiesacept{
    display: flex;
    align-items: center;
    justify-content: center;
}
.centermid{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 350px;
}
.cookiesacept .col-6:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.cookiesacept .col-6:last-child{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.cookiesacept span{
    margin: 0 15px;
}
.cookiesacept button{
    margin: 0!important;
    font-size: 16px!important;
}


.contseparador{
   padding: 0 15%;
}
.contpanelcookies span{
    font-size: 18px;
}
.cookiesacept button{
    background: #000;
    color:#fff;   
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}
.cookiesacept button:focus-visible{
    background: #fff;
    color:#000;
}

.panelcookies hr{
    border-top:#000 solid!important;
}
.cookiesacept img{
    width: 24px;
}
.cookiesacept button{
    margin-right: 10px!important;
    border:1px solid #000;
}
button.aceptar:focus-visible{
    background: #205383!important;
    color:#fff!important;
}
button.noaceptar:focus-visible{
    background: #205383!important;
    color:#fff!important;
}

.cookiesacept.col-12{
    padding: 0!important;
}

button.aceptar{
    border: none;
    margin-right: 0!important;
    background: #006137!important;
}
button.noaceptar{
    border: none;
    margin-right: 0!important;
    background: #822633!important;
   
}
button.noaceptar:hover{
    background: #205383!important;
    color:#fff!important;
    pointer-events: inherit;
}

.show{
    /* display: flex!important; */
}
.hide{
    /* display: none!important; */
}

.fixmg{
    margin: 0!important;
}
.fixmg button{
    right: -71px;
    position: relative;
    top: -17px;
    max-width: 141px;
}
.fixmg2{
    margin-top: 15px;
}
.fixmg2 button.aceptar{
    margin: 0!important;
}

.panelcookies{
    background: #090909ad;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    text-align: center;
    position: fixed;
    z-index: 99999999;
}
.contpanelcookies{
    background: #ffffffde;
    width: 40% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    -webkit-border-radius: 0;
    border-radius: 0;

}
.cookiesacept button:hover{
    background-color: #fff!important;
    color:#000!important;
    border: 1px solid #000;
}
.cerrarcookies button:hover{
    opacity: .5;
}

.cookies{
    position: fixed;
    display: none;
    justify-content: center;
    color: #fff;
    align-items: center;
    font-size: 16px;
    z-index: 999999;
    bottom: 0;
    background: #000; 
    min-height: 70px;
}

.cookies span{
    font-size: 16px;
    padding: 0 10px;
}
.cookies button{
    font-size: 16px!important;

}
.cookies button{
    color:#fff;
    text-decoration: none;
    padding: 8px 12px;
    font-size: 16px;
    background: #006D38;
    margin:0 10px!important;
    border:0;
    font-weight: 600 !important;
   
}
.cookies button:last-child{
    background: #822633!important;
    white-space: nowrap;

}

.cookies button:hover{
    background-color: #fff!important;
    color:#000!important;
}

.cookies button{
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
  
}
button.aceptar{
    border:1px solid #008915!important;
    margin: 0 15px 0 0 !important;
}

button.noaceptar{
    border:1px solid #dc2a43!important;
}
button.aceptar:hover{
    background: #008915!important;
    color:#fff!important;
    border:1px solid #008915!important;
}
button.noaceptar:hover{
    background: #dc2a43!important;
    color:#fff!important;
    border:1px solid #dc2a43!important;
}
.accept2{
    margin-left: 15px!important;
}

.cookies button.configurar{
    background: #fff!important;
    color:#000!important;   
    margin: 0 !important;
}
.cookies button.configurar:hover{
    background: #205383!important;
    color:#fff!important;   
}
.cookies button.configurar:focus-visible{
    background: #205383!important;
    color:#fff!important;   
}
.cookies button.aceptar{
    margin:0!important;
}

.cerrarcookies{
    display: none!important;
    position: relative;
}


/*********/


h1{
    color:#fff!important;
}
h2{
    font-weight: 600!important;
}
.contnovedades h2{
    color:#fff!important;
}
select:focus{
    outline:1px!important;
    border:2px solid #ccc;
}
select:focus-visible{
    outline:1px!important;
    border:2px solid #ccc;
}
select option:focus{
    outline:1px!important;
    border:2px solid #ccc;
}
select option:focus-visible{
    outline:2px solid #000!important;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}
button:focus-visible{
    outline:2px solid #000!important;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}
button.accordion-button:focus-visible{
    outline:2px solid #000!important;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}

select:focus-visible{
    outline:1px solid #000!important;
}
.navbar-nav a:focus-visible{
    border:none;
    outline:2px solid #000!important;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}
.logo:focus-visible{
    border:none;
    outline:2px solid #000!important;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}
.novedad button:focus-visible
 {
    outline:2px solid #000!important;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
 }

.navbar-nav a:focus{
    text-decoration: underline;
}
.volver{
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.contvolver{
    width: 93%;
    max-width:1600px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.volver a{
    color:#000;
    text-decoration: none;
}
.volver a:focus{
    text-decoration: underline;
}
.navbar-nav a{
    color:#000;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
}
.navbar-nav a:hover{
    color:#00ebb8;
}
.navbar-nav a.active{
    color:#00ebb8;
}


.breadcrumb button{
     font-family: 'Goldplay';
}
.breadcrumb{
     font-family: 'Goldplay';
}

.titulo{
    margin-bottom: 15px;
}

.container-fluid{
    background: #fff;
}

.listarrss{
    margin-top:15px;
}

.listarrss img{
    width: 48px;
}
footer a:hover{
    opacity: .5;
}

button:focus-visible {
    
}
.anima button:hover{
    background:#006137;
    color:#fff;
    border:2px solid #006137;
}
.contnovedades button:hover{
    background:#000;
    color:#fff;
    border:1px solid #000;
}
.ampliado
.accordion-body button:hover{
    background:#3C726B!important;
    color:#fff!important;
    border:2px solid #3C726B!important;
}

.anima .col-6:first-child{
    opacity: 0;
    -moz-transform: translateX(-150px) translateY(0px);
    -webkit-transform: translateX(-150px) translateY(0px);
    -o-transform: translateX(-150px) translateY(0px);
    -ms-transform: translateX(-150px) translateY(0px);
    transform: translateX(-150px) translateY(0px);
}
.anima .col-6:last-child{
    opacity: 0;
    -moz-transform: translateX(150px) translateY(0px);
    -webkit-transform: translateX(150px) translateY(0px);
    -o-transform: translateX(150px) translateY(0px);
    -ms-transform: translateX(150px) translateY(0px);
    transform: translateX(150px) translateY(0px);
}


.anima .col-6.visible:first-child{
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    -moz-transform: translateX(0) translateY(0px);
    -webkit-transform: translateX(0) translateY(0px);
    -o-transform: translateX(0) translateY(0px);
    -ms-transform: translateX(0) translateY(0px);
    transform: translateX(0) translateY(0px);
    opacity: 1;
}

.anima .col-6.visible:last-child{
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    -moz-transform: translateX(0) translateY(0px);
    -webkit-transform: translateX(0) translateY(0px);
    -o-transform: translateX(0) translateY(0px);
    -ms-transform: translateX(0) translateY(0px);
    transform: translateX(0) translateY(0px);
    opacity: 1;
}

#root{
    overflow: hidden;
}
body{
    font-family: 'Montserrat';
    font-size: 18px;
}
body p{
    font-size: 20px;
    line-height: 28px;
}

.cabeceraint{
    height: 200px;
    background: url(../img/main1.jpg) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-top: 0;
}

header .container-fluid{
    padding: 0;
}
header .navbar {
    padding: 0 0;
}
header{
    max-width: 100%;
    position: absolute;
    padding: 0 25px 0 0;
    width: 100%;
}
select{
    border: 1px solid #000;
    min-height: 30px;
}
select option{
    border-radius: 0 0 0 0!important;
    -webkit-border-radius: 0 0 0 0!important;
}

/********/

.listapreparacion{
    margin-top: 15px;
}

.listapreparacion li{
    list-style: url("../img/arrowgreen.png");
}

.listaslider{
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin:0;
}
.listaslider li{
    padding: 0;
}
.listaslider li{
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.listaslider li:hover{
    -moz-transform: scale(1.1) translateX(0px) translateY(0px);
    -webkit-transform: scale(1.1) translateX(0px) translateY(0px);
    -o-transform: scale(1.1) translateX(0px) translateY(0px);
    -ms-transform: scale(1.1) translateX(0px) translateY(0px);
    transform: scale(1.1) translateX(0px) translateY(0px);
}

.listaslider li a{
    font-size: 36px;
    color:#fff;
    text-decoration: none;
    font-weight: 600;
    line-height: 46px;
}
.listaslider li:hover.active{
    -moz-transform:none;
    -webkit-transform: none;
    -o-transform:none;
    -ms-transform:none;
    transform: none;
}
.listaslider li.active a{
    color: #00ebb8 !important;
}
.listaslider li a:hover{
    color: #00ebb8 !important;
}
.navint{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin: 0 0 0 0;
}
.navint.col-8{
    padding-right: 8%;
}
.item1.fixpad  li{
    list-style: url("../img/arrowgreen.png");
}
.conthr{
    padding:25px!important;
}
.navint li{
    padding: 0 0;
}
.navint li.active a{
    font-weight: 600;
    color: #02d3a6!important;
}
.navint2 li{
    padding:0 0 10px 0!important;
}
.mgbot15{
    margin-bottom: 15px!important;
}

.item1 .col-6:first-child{
    padding: 0;
}
.btns button{
    padding: 8px 12px;
    -webkit-border-radius: 25px;
    border-radius: 25px;
    border: none;
    font-weight: 600;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.btns button:hover{
    border: none;
}
.btns button.grey{
    background: #898989;
    color: #fff;
}
.btns button.grey:hover{
    background: #5c5c5c;
    color: #fff;
}
.btns button.green{
    background: #02d3a6;
    color: #fff;
   ;
}
.btns button.green:hover{
    background: #00886a;
    color:#fff;
}
.btns button.green2{
    background: #039677;
    color: #fff;
   ;
}
.btns button.green2:hover{
    background: #017c62;
    color:#fff;
}

.navint li a:hover{
    color: #7dd29f!important;
    
}
.navint li a{
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    font-size: 28px;
    color: #5c5c5c;
    text-decoration: none;
    font-weight: 400;
    line-height: 40px;
}
.navint li:hover.active{
    -moz-transform:none;
    -webkit-transform: none;
    -o-transform:none;
    -ms-transform:none;
    transform: none;
}

.navbar-nav a{
    font-weight: 600;
}
.navbar-nav a:hover{
    color: #00ebb8!important;
}

.navbar-nav a.active{
    font-weight: 600;
    color: #00ebb8!important;
}

.despliega{
    width: 17px;
    margin-left: 5px;
    top: -2px;
    position: relative;
}
.activasubmenu .despliega{
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.slider{
    min-height: 100vh;
    width: 100%;
    background: url(../img/slider1.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    align-items: center;
    justify-content: center;
    display: flex;
}
.slider img{
    width: 200px;
}
.slider .col-6:first-child{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 3%;
}
.slider h2{
    color: #fff;
    font-size: 50px;
    line-height: 56px;
    margin-bottom: 25px;
    padding-right: 10%;
}
.wassap {
    position: fixed;
    bottom: 0;
    z-index: 99999;
    border: 0;
    outline: 0;
    width: auto;
    left: 10px;
    bottom: 10px;
    -webkit-transition: all .50s ease;
    -moz-transition: all .50s ease;
    -ms-transition: all .50s ease;
    -o-transition: all .50s ease;
    transition: all .50s ease;
}
.wassap:hover{
    opacity: .5;
}
.wassap img{
    width: 70px;
}
.migapan{
    max-width: 1800px;
    padding: .5% 5%;
    margin:0 auto;
}
.migapan button{
    padding: 0;
    background: none;
    font-size: 18px!important;
    width: auto;
    margin-top: 0!important;
    border:0;
    color:#00a65d!important;
    line-height: 16px;
}
.migapan.azul button{
    color:#205383!important;
}
.migapan.azul a{
    color:#205383!important;
}
img.logo{
    max-height: 60px;
    padding: 0px 0 0 0;
}
.headerMain{
    display: flex;
    z-index: 999999;
    align-items: center;
    justify-content: space-between;
}
.cont_logo{
    max-width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.mapaweb ul{
    list-style: url("../img/arrowgreen.png");
}
h1.titularmapaweb{
    color:#000!important;
    margin: 45px 0 0 0!important;
    padding: 0!important;
    font-size: 30px;
}
.intsection.mapaweb{
    display: flex;
    align-items: flex-start;
}
footer{
    min-height: 170px;
    background: #00c69b;
    display: flex;
    align-items: center;
    padding:55px;
}
.cont_footer .logopie{
    max-width: 250px;
    margin-top:0;
}
#cursos p{
    margin: 0;
}

.cont_footer{
     width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cont_footer .col-3{
    padding: 3% 0;
}
.cont_footer .col-3 h4{
    font-size: 30px!important;
    line-height: 38px;
}
.cont_footer .col-3 ul{
    list-style: url("../img/arrowwhite.png");

}
.cont_footer .col-3 ul li{
    /* list-style: url("../img/arrowwhite.png"); */
    list-style:none;
}
.cont_footer .col-3 h4 a{
    font-size: 30px;
}


.sliderimg{
    width: 950px;
    position: relative;
}
.info{
    display: flex;
    justify-content: center;
    max-width: 1800px;
}
button{
    padding: 8px 12px;
    background: none;
    font-size: 18px!important;
    width: 200px;
}
.info .col-6 h2{
    font-size:36px;
    color: #fff;
    font-weight: bold;
}
.info .col-6:first-child{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 80%;
    flex-direction: column;
}
.info .col-6 p{
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    margin: 5px 0;
}
.sliderimg2{
    position: relative;
    width: 100%;
}
.sliderimg3{
    position: relative;
    width: 100%;
    max-width: 640px !important;
}
.sliderimg4{
    position: relative;
    margin-top:30%
}
.sliderimg5{
    position: relative;
}
.sliderimg6{
    position: relative;
}
.item1{
    min-height: 80vh;
}
.item1 .col-6{
    padding: 2%!important;
}
.item1 .col-6:first-child > p{
    margin-bottom: 25px;
}
.item1 .col-12{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    padding: 25px;
}
.item1{
    display: flex;
    justify-content: center;
}
.item1 .col-6:first-child{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}
.item1 .col-6:last-child{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
}
.item1.fixpad > .col-12{
    padding: 5% 2%!important;
}
.item1.fixpad .col-6:last-child{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: right;
    justify-content: flex-start;
}
.item1.fixpad .col-6:last-child p{
    text-align: left;
    margin-bottom: 35px;
}
.item1.fixpad .col-6:last-child a{
    font-size: 24px;
    line-height: 36px;
    line-height: 1em;
}
.item1.fixpad .col-6:first-child h2{
    margin-bottom: 20px;
}

.item1.fixpad .col-6 li img{
    width: 17px;
    height: 17px;
    margin: 5px;
}
.item1.fixpad .col-6 li{

}
.item1.fixpad .col-6 li{
    margin-bottom: 5px;
}

.mgtop15{
    margin-top:15px;
}

.contnovedades
h4 {
    font-size: 28px!important;
    font-family: 'Montserrat';
    font-weight: bold;
}
h4 {
    font-size: 48px!important;
    font-family: 'Montserrat';
    margin-bottom: 15px;
    line-height: 52px;
    font-weight: bold!important;
}
.item2{
    min-height: 80vh;
}
.item2 .col-12{
    display: flex;
}
.item2{
    display: flex;
    justify-content: center;
    padding: 0;
}
.grey{
    background: #f2f2f2;
}
.item2 > .col-12{
    padding: 0 5%;
}
.item1 > .col-12{
    padding: 2%;
}
.item2 > .col-12{
    max-width: 1600px;
}
.item1 > .col-12{
    max-width: 1600px;
}
.pdbot25{
    margin-bottom: 25px;
}
.col-12.frow{
    flex-direction:row;
    align-items:flex-start;
}
.frow .col-4 img{
    width: 100%;
}
.frow button{
    margin-top: 0!important;
}

.item2 .col-6:first-child{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
}
.item2 .col-6:last-child{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}
.item2 h2{
    font-size: 48px;
    margin-bottom: 25px;
}


.item1 .fixwidth{

}
.ensancha{
    justify-content: space-between;
}
.iconmail{
    width: 31px;
    margin: -9px 15px 0 0px;
}
.iconphone{
    width: 31px;
    margin: -9px 15px 0 0px;
}
footer .col-4:first-child ul{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
}
footer .col-4:last-child ul{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;
}
footer .col-4:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}
footer .col-4:last-child ul li:first-child img{
    width: 60px;
}
footer .col-4:first-child h4{
    font-size: 24px!important;
    color: #fff;
    line-height: 28px;
}
footer .col2footer{
    display: flex;
    justify-content: flex-end;
    color: #fff;
    font-size: 24px;
    line-height: 28px;
    flex-direction: column;
}
footer ul li {
    font-size: 20px;
    line-height: 24px;
    margin: 5px 0 !important;
}
footer ul li a{
    font-size: 16px;
    line-height: 22px;
    margin: 4px;
}
footer ul{
    padding-left: 0;
}
.listarrss{
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.listarrss li{
    width: auto;
    margin:0 5px!important;
    list-style: none!important;
}
.listarrss button{
    padding:0;
    border:0;
    outline:0;
    width: auto;
}
.listarrss button img{
    width: 40px;
}
.cont_footer{
    max-width: 1500px;
    margin:0 auto
}
.cont_footer .col-3:last-child{
    padding: 3% 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}
.cont_footer .col-12{
    width: 100%;
    display: flex;
    padding: 1% 0 3% 0;
    justify-content: center;
    align-items: center;
    color: #fff;
    justify-content: space-between;
}
.cont_footer .col-3:nth-child(2){
    display: flex;
    padding: 3% 0;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column;
}
.cont_footer .col-3:nth-child(1) img{
    width: 50px; 
    margin-bottom: 20px;
}
.cont_footer .col-3:nth-child(3) img{
    width: 70px;
    margin-bottom: 20px;
}
.cont_footer .col-3:nth-child(2) img{
    margin-bottom: 50px;
}
.cont_footer .col-3:nth-child(1) ul img{
    width: 21px;
    margin: -7px 5px 0 0;
}
.cont_footer .col-3:nth-child(1) a{
    color:#fff;
    font-size: 20px;
    text-decoration: none;
}
.cont_footer .col-3 a {
    color:#fff;
    text-decoration: none;
    font-size: 20px;
    margin:0;
    -webkit-transition: all .50s ease;
    -moz-transition: all .50s ease;
    -ms-transition: all .50s ease;
    -o-transition: all .50s ease;
    transition: all .50s ease;
    display:inline-block;
}
.navbar-nav a{
    -webkit-transition: all .50s ease;
    -moz-transition: all .50s ease;
    -ms-transition: all .50s ease;
    -o-transition: all .50s ease;
    transition: all .50s ease;
}

.cont_footer .col-3:nth-child(2){
    text-align: center;
}
.cont_footer .col-3:nth-child(1) li{
    margin-top:15px!important;
}
.cont_footer .col-3:nth-child(1) li:nth-child(2){
    margin-top:25px!important;
}

strong{
    /* font-family: 'Montserrat'; */
}
.intsection .col-12 .col-4 .col-12{
    display: flex;
    flex-direction: column;
}




.ampliado.error2{
    padding:0!important;
}
.error2 .centermid{
    align-items: center !important;
    height: auto;
    min-height: auto;
    margin-bottom: 45px !important;
}

.sub-menu{
    margin: 0;
    list-style: none;
    padding: 0 0 0 13px;
    display: none;
    position: absolute;
    z-index: 9999;
    top: 80.3px;
    width: 100%;
    min-width: 260px;
}
.sub-menu li{
    padding: 5px;
    border-left: 4px solid #00ebb8;
    padding: 0 0 0 25px;
}
.sub-menu a{
    color:#fff!important;
}
.sub-menu a.active{
    color:#fff;
}


.navbar-nav li:nth-child(4):hover .sub-menu{
    display: block; 
}

.sub-menu li:last-child{
    margin:0!important;
}

.intsection.puzzle .col-12 {
    display: flex;
    flex-direction: row!important;
}
.intsection.puzzle .col-12:first-child .col-6:last-child{
    align-items: center!important;
    padding:0!important;
}
.puzzle
.col-12.dflex .col-6{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.puzzle img{
    width: 100%;
    max-width: 550px;
}


.intsection.puzzle .col-12:first-child .col-6:last-child > div{
    width: 550px!important;
    height: 309px!important;
} 


 #player{
    width: 550px!important;
    height: 309px!important;
} 

/*testimonios*/

#equipo h2{
    font-weight: 600 !important;
    font-size: 58px;
    color:#fff;
}
#equipoint h2{
    font-weight: 600 !important;
    font-size: 58px;
    color:#00c69b!important;
}
#equipoint em{
    color:#5c5c5c;
    font-size: 20px;
}
#equipoint
.listaequipo li strong {
    font-size: 24px;
    color:#5c5c5c;
    margin-bottom: 5px;
}

.listaequipo li{
    list-style:none;
    width: 33.3333%;
    padding: 2%;
}
.listaequipo li strong{
    font-size: 24px;
    display: block;
    text-align: center;
    color:#00c69b;

}
.listaequipo li em{
    font-size: 24px;
    display: block;
    text-align: center;
}
.listaequipo{
    margin-top:25px;
}
.listaequipo ul{
    display: flex;
    width: 100%!important;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    color: #fff;
    padding:0;
}
.jccenter h2, .jccenter h3{
    text-align: center;
    color:#fff;
}
.col-12.jccenter{
    justify-content: center;
}
#testimonios h2 {
    font-weight: 600 !important;
    font-size: 58px;
    color:#02d3a6;
}
#testimonios > .col-12{
    flex-direction: column;
    text-align: center;
    padding: 0 0 25px 0;
}
.item1#testimonios {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.item1#testimonios .col-12{
    max-width: 100%!important;
}
.item1#testimonios .col-12.listatestimonios{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    justify-content: center;
    padding: 1%;
}
.col-12.listatestimonios .col-3{
    padding: 0 2%!important;
}
.listatestimonios h3{
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 10px;
    color: #5c5c5c;
}
.listatestimonios b{
    font-size: 22px;
    font-weight: normal;
    color: #02d3a6;
    font-style: normal;
    padding-bottom: 20px !important;
    display: block;
    font-weight: 500;
    line-height: 28px;
}
.listatestimonios p{
    font-style: italic;
}
.aligntop{
    align-items: flex-start!important;
    margin-top:20px;
    padding: 0!important;
}
#cursos
.aligntop ul.col-6{
    padding: 0 0 0 20px!important;
    margin: 0!important;
}
#cursos .modalidades .col-6 p:last-child {
    font-weight: normal;
    margin-top: 0;
    font-size: 20px;
}

/*contacto*/

#contacto h2{
    display: block;
}
#contacto p{
    padding: 0 5%;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
}
#contacto p:last-child{
    margin-bottom: 105px;
}

.jccenter h2, .jccenter h3{
    text-align: center;
    color:#fff;
}
.col-12.jccenter{
    justify-content: center;
}
#contacto h2 {
    font-weight: 600 !important;
    font-size: 58px;
    color:#02d3a6;
}
#contacto > .col-12{
    flex-direction: column;
    text-align: center;
    padding: 0 0 0px 0;
}
.item2#contacto > .col-12 {
    width: 100%;
    max-width: 100%;
}
.item2#contacto{
    display: flex;
    justify-content: center;
    padding: 0;
    align-items: center;
    flex-direction: column;
    min-height: auto;
    margin-top: 140px;
}
.listalegal{
    display: flex;
    justify-content: center;
}
.listalegal li{
    padding: 0 5px;
    text-wrap: nowrap;
}
.logomovil{
    display: none;
}

button:focus-visible{
    outline: 0 !important;
    border:0  !important; 
}
button:focus{
    outline:0 !important;
    border:0 !important;
}
.navbar-toggler{
    border: none!important;
    width: 70px;
    right: 20px!important;
}
.navbar-toggler:focus{
    -webkit-box-shadow:none !important;
    box-shadow: none !important;
}


@media only screen and  (max-width: 1700px){
}

@media only screen and  (max-width: 1700px){
    .contpanelcookies {
        width: 70%!important;
    }
    .migapan.error {
        padding: 0 35px !important;
    }
}

@media only screen and  (max-width: 1400px){
    .contpanelcookies {
      width: 90%!important;
    }
    .migapan.error {
      padding: 0 35px !important;
    }
    #player{
      width: 550px!important;
      height: 309px!important;
} 

.intsection.puzzle .col-12:first-child .col-6:last-child > div{
      width: 550px!important;
      height: 309px!important;    
} 
.intsection.puzzle .col-12:first-child .col-6:last-child {
     align-items: flex-start!important;
}
}

@media only screen and  (max-width: 1200px){


    .item2 > .col-12 {
        padding: 5%;
    }
    .cont_footer .col-3 .listalegal a{
        font-size: 16px;
    }
     .col-12.listatestimonios .col-3 {
        width: 33.3333%!important;
    }
    .col-12.listatestimonios .col-3 {
        padding: 2% !important;
    }
    .item1{
        padding: 5%;
    }
}
@media only screen and  (max-width: 1100px){

}
@media only screen and  (max-width: 991px){

    .cont_footer .col-3:nth-child(2){
        order: 3;
    }
    .cont_footer .col-3:nth-child(2) > img{
        display:none;
    }
    .cont_footer .col-3:last-child ul{
        margin:0 0 30px 0;
    }
    .cont_footer .col-3:last-child{
        margin:30px 0;
    }
    .cont_footer .col-3:nth-child(1) ul img {
        width: 20px;
        margin: -5px 15px 0 0;
    }
    .listarrss a{
        height: 50px;
    }
    .headerMain {
    }
    .headerMain {
        padding:0;
        position: relative;
    }
    header .navbar {
        padding: 0 0;
        width: 100%;
    }
    .navbar-expand-lg .navbar-nav {
    }
    .navbar>.container-fluid{
        justify-content: flex-end;     
        background: #0da685f2  !important;
    }
    #inicio{
        display: none;
    }
    .logo{
        display: none;
    }
    .logomovil{
        display: block;
        width: 150px;
        margin: 15px 0 0 15px;
        z-index: 999;
        padding-bottom: 15px;
    }
    .navbar-nav a {
        display: block;
    }
    .container-fluid{
        position: absolute;
        top: 0;
    }
    .navbar-expand-lg .navbar-nav {
    }
    .nav-link {
        height: auto;
    }
    .navbar-expand-lg .navbar-nav {
    }
    .nav-item{
        width: 100%;
        text-align: center;
        padding: 15px 0;
    }
    #navbar-toggler{
        border: none;
        width: 50px;
        margin-right: 20px!important;
        position: relative;
        border: 0;
        top: 0;
    }
    
 
   .navbar-toggler.collapsed.show .navbar-toggler-icon{
    background-image: url(../img/close.png)!important;
    }

    .navbar-toggler.collapsed .navbar-toggler-icon{
        background-image: url(../img/hamburguesa.png)!important;
       
    
    }  
  

    .nmovil {
        width: 100%!important;
        padding: 25px 0 25px 25px!important;
        border-bottom: 1px solid #4cdfbf;
        border-top: 1px solid #4cdfbf;
    }
    .nmovil a {
        display: block;
        padding: 0 !important;
        text-align: left!important;
    }
    .nmovil {
        border-bottom: none;
    }
    .nmovil{
        padding-top: 15px;
    }
    .logomovil{
        margin: 25px 0 0 25px;
    }
    .navbar-collapse{
        margin-top: 15px;
    }
    .slider h2 {
        color: #fff;
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 25px;
        padding-right: 15%;
    }
    .item2 > .col-12 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px;
        width: 100%;
    }
    .item2 .col-6{
        width: 100%;
    }
    .sliderimg3 {
        position: relative;
        width: 60%;
        max-width: 640px !important;
        margin: 0 auto;
    }
   .col-12.jccenter{
        flex-direction: column-reverse !important;
   }
   .cont_footer .col-12 {
        width: 100%;
        display: flex;
        padding: 1% 0 3% 0;
        justify-content: center;
        align-items: center;
        color: #fff;
        justify-content: space-between;
        flex-direction: column;
        align-content: center;
    }
    .cont_footer .col-3 {
        padding: 3% 0;
        width: 100%!important;
        text-align: center;
    }
    .cont_footer .col-3:last-child {
        padding: 3% 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    #cursos .frow {
        flex-direction: column;
        align-items: center;
        display: flex;
        padding: 25px;
        width: 100%;
        text-align: left;
    }
    #cursos .frow .col-6:last-child {
        align-items: center !important;
        width: 100%;
        margin-top: 45px;
    }
    #cursos .frow .col-6:last-child img{
        width: 100%;
    }
    #cursos .col-12.tright {
        align-items: center;
        flex-direction: column;
        display: flex;
        padding: 25px;
        width: 100%;
        width: fit-content;
        text-align: left;
    }
    #cursos .col-12.tright .col-6{
        padding: 0 !important;
        width: 100%;
        text-align: left;
        display: flex;
        align-items: flex-start !important;
    }
    #cursos .col-12.tright .col-6 img{
        margin-top: 45px;
        width: 100%;
    }
    #cursos .col-6 {
        padding: 0 !important;
        width: 100%;
    }
    #contacto p{
        margin-bottom: 34px!important;
    }
    .item2#contacto{
        margin-top: 40px;
    }
    .item1#testimonios{
        margin-top: 45px;
    }
    #equipo .col-12.jccenter {
        flex-direction: column !important;
    }
    .slider .col-6:first-child{
        padding-right: 7%;
    }
    #cursos .col-6 p:last-child{
        font-size: 30px;
    }
}
@media only screen and  (max-width: 768px){  

    .slider{
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .slider .col-6:first-child{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 0;
    }
    .slider .col-6{
        width: 100%;
    }
    .slider h2{
        padding: 0;
        text-align: center;
        width: 100%;
        margin-top: 45px;
    }
    .listaslider {
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .item2 h2, #equipo h2 .jccenter h2, #testimonios h2{
        font-size: 40px;
        margin-bottom: 25px;
        line-height: 48px;
    }
    .col-12.listatestimonios .col-3 {
        padding:2% !important;
        width:100% !important;
    }
    .col-12.listatestimonios{
        padding: 5% !important;
    }
    .item1#testimonios .col-12.listatestimonios{
        display: flex;
        flex-direction: column;
        max-width: 100%;
        justify-content: center;
        padding: 1%;
        align-items: center;
        width: 100%;
        text-align: center;
    }
    .listaequipo ul {
        display: flex;
        width: 100% !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        color: #fff;
        padding: 0;
    }
    .listaequipo {
        padding: 25px 0;
    }
    .listaequipo li {
        padding: 2% 0;
    }
    .item1 {
        padding:45px 25px;
    }


}
@media only screen and  (max-width: 768px){

    .cookies span {
        font-size: 17px;
        padding: 0 10px;
        width: 100% !important;
        display: block;
        text-align: center;
    }
    .listaequipo li {
        list-style: none;
        width: 100%;
        padding: 2%;
    }

    .cookies{
        flex-direction: column;
        padding: 15px 0;
    }
    .cookiesacept{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .cookiesacept .col-4:last-child{
        margin-left: 0;
        margin-top: 0;
        width: 100%;
        flex-direction: column;    
    }
    .cookiesacept .col-4:last-child button{
        margin-top: 15px;
        margin: 5px 0!important;

    }
    .fixmg button{
        right: 2px;
        position: relative;
        top: 0;
        max-width: 203px;
    }

    .cookies button{
        margin: 5px 0!important;
        padding: 3px;
    }
    .cookiesacept button{
        padding: 3px;
    }
    .contpanelcookies {
        height: 70%;
    }
    .slider h2 {
        text-align: center;
        width: 100%;
        margin-top: 45px;
        font-size: 30px;
        line-height: 36px;
        padding: 0 15px;
    }
    .listaslider li a {
        font-size: 24px;
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        line-height: 60px;
        padding: 0 15px;
    }
    .slider img {
        width: 130px;
    }
    .slider{
        padding-top: 75px;
        min-height: 876px;
    }
    footer ul li {
        font-size: 24px;
        line-height: 32px;
      
    }
    .col-12.listatestimonios {
        padding: 0 !important;
    }
    .listapreparacion {
        margin-top: 15px;
        padding: 0;
    }
    .listaequipo{
        padding: 0;
    }
    .listaequipo li{
        padding: 20px 0;
    }
    .logomovil {
        margin: 15px 0 0 15px;
    }
    footer {
        padding: 45px 25px;
    }
    #equipo h2 {
        font-size: 40px;
        line-height: 48px;
    }
    
}

